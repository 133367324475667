import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/marie-kl-young.jpg";
  const fullName = "Marie K.L. Young (Chow)";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const birthDate = "January 25th, 1938";
  const deathDate = "November 4th, 2020";
  const memorialDate = "Saturday November 21st, 2020";
  const memorialTime = "12:30pm ET";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl = "https://www.youtube.com/embed/n0uqbgAMCrk";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      recordingEmbedUrl={recordingEmbedUrl}
      customColumnSpacing="1fr 3fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    ></StoryPage>
  );
};

export default ThisPage;
